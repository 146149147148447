@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

body {
  font-weight: 300;
  font-style: normal;
  font-family: Chromatophore, helvetica, arial, sans-serif;
  line-height: 1.5;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
}

input {
  background-color: rgb(24, 0, 72);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.allura-regular {
  font-family: "Allura", cursive;
  font-weight: 400;
  font-style: normal;
}

@media screen and (min-width: 768px) {
  .home {
    height: calc(100vh - (377px + 56px));

  }
}


.dark body {
  background: rgb(24, 0, 72);
}

body {
  background: white;
}

@layer utilities {

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator {
    @apply dark:invert cursor-pointer;
  }
}