body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}


main {
  padding: 0.5rem;

  @media screen and (max-width: 768px) {
    margin-top: 48px;
  }

}

@media screen and (max-width: 768px) {
  main {
    padding: 1rem;
  }


}

section {
  margin-bottom: 2rem;
}


form label {
  display: block;
  margin-top: 1rem;
}

form input,
form select,
form button {
  display: block;
  margin-top: 0.5rem;
}

input:not([type="checkbox"]),
button,
textarea,
select {
  @apply appearance-none border-2 rounded-md px-4 py-2 outline-none focus:border-blue-500 w-full bg-white dark:bg-mainBackground text-lg text-secondaryText dark:text-white;

}



p {
  @apply text-lg text-gray-700 my-2;
}

h2 {
  @apply text-3xl font-bold
}

.gradient-text {
  @apply bg-gradient-to-r from-red-500 via-purple-500 to-indigo-500 bg-clip-text text-transparent;
}