.header {
  width: 100%;
  z-index: 1000;
}

@media screen and (min-width: 768px) {
  .centerLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.language-switcher {
  display: flex;
  gap: 15px;
  cursor: pointer;
  margin-bottom: 20px;
  top: 14px;
  left: 20px;
  // position: absolute;


  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;


  @media screen and (max-width: 767px) {

    &.menu-open {
      opacity: 1;
      visibility: visible;
    }

    &.menu-closed {
      opacity: 0;
      visibility: hidden;
    }
  }

  & span img {
    width: 24px;
  }
}